import React, {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useContext,
} from 'react';
import Papa from 'papaparse';
import moment from 'moment';
import Lottie from 'react-lottie';
import { useTimeout } from 'react-use';
import { useTranslation } from 'react-i18next';

import { useInfoPopup } from 'hooks';
import { StepsContext } from 'components/Steps';

import { useValidateEventData } from 'utils/importCsv';
import { Button, FileInput } from 'components';
import { EventData } from '.';
import { Community, Group, Profile } from 'types';
import {
  AnimationContainer,
  ComponentInfoText,
  ComponentTitle,
  ExportModuleWrapper,
  FilePreview,
  ImportButton,
  ImportButtonWrapper,
  MaxWidthWrapper,
  StyledDescriptionList,
  SuccessMessage,
  TextWrapper,
} from './styles';
import SuccessAnimation from 'static/animations/success-check.json';
import InfoAnimation from 'static/animations/warning-sign.json';
import FileBox from 'components/Inputs/FileInput/FileBox';
import { CsvIcon } from 'static';
import { EXPORT_FIELDS } from 'constants/export_csv';
import { useSelector } from 'react-redux';
import { getEventTypes } from 'store/selectors/eventTypes';

type Props = {
  user: Profile;
  community: Community;
  group?: Group;
  setEventData: Dispatch<SetStateAction<Array<EventData>>>;
  validationSuccess: boolean;
  setValidationSuccess: Dispatch<SetStateAction<boolean>>;
  data: File | null;
  setData: Dispatch<SetStateAction<File>>;
  uniqueEventTypes: string[];
  setUniqueEventTypes: Dispatch<SetStateAction<Array<string>>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const ImportData: React.FC<Props> = ({
  user,
  community,
  group,
  setEventData,
  validationSuccess,
  setValidationSuccess,
  data,
  setData,
  uniqueEventTypes,
  setUniqueEventTypes,
  loading,
  setLoading,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const { showInfoPopup } = useInfoPopup();
  const { dataValidation } = useValidateEventData();
  const eventTypes = useSelector(getEventTypes) || [];
  let timer = undefined;

  const {
    allowNextStep,
    allowPreviousStep,
    isLastStep,
    setCondition,
    triggerNextStep,
  } = useContext(StepsContext);

  const OPTIONS = {
    animationData: SuccessAnimation,
    loop: false,
    autoplay: true,
  };

  function formatDateTime(date, from, to, format = 'DD-MM-YYYY') {
    const eventDate = moment(date, format);
    const tempDate = moment(date);
    let formattedDate = '';

    if (eventDate.isValid() || tempDate.isValid()) {
      // Format the date based on the valid moment object
      if (eventDate.isValid()) {
        formattedDate = eventDate.format('LL');
      } else if (tempDate.isValid()) {
        formattedDate = tempDate.format('LL');
      }
    }

    // Calculate start and end times
    const startTime = moment(formattedDate + ' ' + from);
    const endTime = moment(formattedDate + ' ' + to);

    // Return the formatted date and times as ISO strings
    return {
      from: startTime.toISOString(),
      to: endTime.toISOString(),
    };
  }

  const handleFileImport = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setCondition(false, true);
    let row = 0;
    let errorFound = false;
    const dataArray: Array<EventData> = [];
    const file = e.target.files[0];
    if (file instanceof File) setData(file);
    const eventTypesNameList = [];

    Papa.parse(file, {
      worker: true,
      header: true,
      skipEmptyLines: true,
      step: (result, parser) => {
        row++;
        const data = { ...result.data };

        const shouldSkip = (obj) =>
          Object.values(obj).every((value) => value === '');

        if (shouldSkip(data)) {
          return;
        }

        const validationError = dataValidation({ ...data, row });

        if (validationError) {
          errorFound = true;
          setErrorMessage(validationError.errorMessage);
          setValidationSuccess(false);
          showInfoPopup({
            title: validationError.errorType,
            richMessage: validationError.errorMessage,
            type: 'error',
            loop: false,
          });
          parser.abort();
        } else {
          const {
            [EXPORT_FIELDS.DESCRIPTION]: description,
            [EXPORT_FIELDS.EVENT_TYPE_NAME]: eventTypeName,
            [EXPORT_FIELDS.DATE]: date,
            [EXPORT_FIELDS.FROM]: from,
            [EXPORT_FIELDS.TO]: to,
            [EXPORT_FIELDS.LOCATION]: location,
            [EXPORT_FIELDS.TITLE]: title,
            [EXPORT_FIELDS.ALL_DAY]: allDay,
          } = result.data;
          const resultDate = formatDateTime(date, from, to);

          const ifExists =
            eventTypesNameList && eventTypesNameList?.includes(eventTypeName);
          const secondIfExists =
            eventTypes && eventTypes?.some((li) => li.title === eventTypeName);
          if (!ifExists || !secondIfExists) {
            eventTypesNameList.push(eventTypeName);
          }
          dataArray.push({
            title,
            description,
            from: resultDate?.from,
            to: resultDate?.to,
            location,
            allDay: allDay === 'yes' ? true : false,
            recipients: [],
            tasks: [],
            documents: [],
            isRepeated: false,
            eventSeriesId: null,
            // userId: user.userId,
            // communityId: community.id,
            // groupId: group?.id ?? '',
            eventTypeName,
            notfiy: { notifyText: '', toNotify: false },
          });
        }
      },
      complete: (results, file) => {
        setEventData(dataArray);
        if (errorFound) {
          setLoading(false);
          setValidationSuccess(false);
        } else {
          setErrorMessage(null);
          setValidationSuccess(true);
          setUniqueEventTypes([...new Set(eventTypesNameList)]);
          timer = setTimeout(() => {
            setCondition(true, true);
            triggerNextStep();
            setLoading(false);
          }, 3000);
        }
      },
      error: (error, file) => {
        setLoading(false);
        showInfoPopup({
          title: 'Unable to parse the file.',
          message:
            'An error occured while parsing the file. Please check the file format.',
          type: 'error',
        });
      },
    });
  };

  useEffect(() => {
    setCondition(validationSuccess ? true : false, true);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <ExportModuleWrapper>
      <ComponentTitle>
        <p>{t('bulkUploadEvents.stepTwo.componentTitle')}</p>
      </ComponentTitle>

      <MaxWidthWrapper>
        <TextWrapper>
          <ComponentInfoText>
            <p
              dangerouslySetInnerHTML={{
                __html: t('bulkUploadEvents.stepTwo.componentDescription'),
              }}
            ></p>
          </ComponentInfoText>
          <ComponentInfoText>
            <StyledDescriptionList>
              <li>{t('bulkUploadEvents.stepTwo.p1')}</li>
            </StyledDescriptionList>
          </ComponentInfoText>
        </TextWrapper>
      </MaxWidthWrapper>

      <ImportButtonWrapper>
        <ImportButton error={errorMessage}>
          {data ? (
            <FilePreview>
              <CsvIcon />
              <FilePreview.Text>{data?.name}</FilePreview.Text>
            </FilePreview>
          ) : (
            <FileBox
              text={t('bulkUploadEvents.stepTwo.uploadButton')}
              tipText={t('bulkUploadEvents.stepTwo.maximumFileSize')}
              isLoading={false}
            />
          )}
        </ImportButton>
        <input
          disabled={loading}
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileImport}
          onClick={(e) => {
            e.currentTarget.value = null;
          }}
          accept=".csv"
        />
      </ImportButtonWrapper>
      {errorMessage && (
        <MaxWidthWrapper>
          <SuccessMessage>
            <AnimationContainer>
              <Lottie
                options={{ ...OPTIONS, animationData: InfoAnimation }}
                width="80px"
                height="80px"
              />
            </AnimationContainer>
            <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
          </SuccessMessage>
        </MaxWidthWrapper>
      )}
      {validationSuccess && (
        <MaxWidthWrapper>
          <SuccessMessage>
            <AnimationContainer>
              <Lottie options={OPTIONS} width="80px" height="80px" />
            </AnimationContainer>
            <div>{t('bulkUploadEvents.successMessage')}</div>
          </SuccessMessage>
        </MaxWidthWrapper>
      )}
    </ExportModuleWrapper>
  );
};

export default ImportData;
